import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SchoolManageService } from 'src/app/services/school-manage.service';
import * as _moment from "moment";
import { IebRegisterService } from 'src/app/services/ieb-register.service';
import { IebParentService } from 'src/app/services/ieb-parent.service';
import { IebKidService } from 'src/app/services/ieb-kid.service';
import { MatSnackBar } from '@angular/material';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { LoadService } from 'src/app/services/load.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegMemberSearchResult } from 'src/app/models/school/reg-member-search-result';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';
import { ParentRequest } from 'src/app/models/school/parent-request';
import { KidRequest } from 'src/app/models/school/kid-request';
import { ParentModel } from 'src/app/models/school/parent-model';
import { RegRequest } from 'src/app/models/school/reg-request';




const moment = _moment;
@Component({
  selector: 'app-ieb-new-reg',
  templateUrl: './ieb-new-reg.component.html',
  styleUrls: ['./ieb-new-reg.component.scss']
})
export class IebNewRegComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  private memberSearchSub = new Subject<string>();
  searchSource$ = this.schoolService.regMemberSource$;
  headerTitle : string;
  step: string;
  disableNext: boolean;
  disableRegister: boolean;
  selectedMember : RegMemberSearchResult;
  name: string;

  kidsVM$ = this.kidService.kidsAdminModel$.pipe(
    map((model) => {
      this.disableNext = model.schoolKids && model.schoolKids.length > 0 ? false : true
      return model;
    })
  );;
  regFormVM$ = this.registerService.regFormAdminSource$;
  regVM$ = this.registerService.regAdminModel$;
  confirm$ = this.confirmService.confirmation$.pipe(
    map((confirm) => {
      if(confirm.toggle) {
        this.showConfirmationDialog(confirm.message, confirm.type);
      }

    })
  );
  load$ = this.loadService.load$.pipe(
    map((load) => {
      if(load) {
        //this.load = false;
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
      return load;
    })
  );
  constructor(private schoolService: SchoolManageService, private registerService: IebRegisterService,
    private parentService: IebParentService, private kidService: IebKidService,
    private snackBar: MatSnackBar, private confirmService: ConfirmationService,
    private loadService : LoadService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    let dt = moment().clone().local();
    let dtMonth = dt.month() +1;
    let dtYear = dt.year();
    let semester = dtMonth > 5 ? 'Fall' : 'Spring'
    this.headerTitle = `Cash Registration ${semester} ${dtYear}`;
    let searchSubscription$ = this.memberSearchSub.pipe(debounceTime(500)).subscribe((query: string) => {
      this.searchChanged(query);
    });
    this.subscription.add(searchSubscription$);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchChanged(query: string): void {
    this.schoolService.regMemberSearch(query);
  }



  onSelectedMember( result: RegMemberSearchResult) : void {
   this.selectedMember = result;
   this.name = result.name;
  }

  cancelSearch() {
    this.selectedMember = null;
    this.name = '';
    this.step = '';
    this.disableRegister = false;
  }
  register() {
    this.registerService.regForMemberSelected(this.selectedMember.memberId);
    this.step = 'kids';
    this.disableRegister = true;
    if(!this.selectedMember.schoolParentId) {
      let parentSubscription = this.parentService.addParentByAdmin(this.selectedMember.memberId).subscribe((data) => {
        if(data) {
          this.kidService.memberSelected(this.selectedMember.memberId);
        }
      });
      this.subscription.add(parentSubscription);
    } else {
      this.kidService.memberSelected(this.selectedMember.memberId);
    }
  }



  onAddNewKid(newKid: KidRequest) {
    this.kidService.newKidAdd(newKid);
  }

  onUpdateNewKid(editKid: KidRequest) {
    this.kidService.kidUpdate(editKid);
  }

  onDeleteKid(deleteKid: KidRequest) {
    this.kidService.kidDelete(deleteKid);
  }

  onAddNewReg(newReg: RegRequest) {
    this.registerService.newRegAdd(newReg);
    this.step = '';
  }

  nextClick() {
    if(this.step === 'parent') {
      this.kidService.memberSelected(this.selectedMember.memberId);
      this.step = 'kids';
    } else if(this.step === 'kids') {
      this.registerService.memberSelected(this.selectedMember.memberId);
      this.step = 'payment';
    }
  }

  backClick() {
    this.step = 'kids';
    // if(this.step === 'kids') {
    //   this.step = 'parent';
    // } else if(this.step === 'payment') {
    //   this.step = 'kids';
    // }
  }

  showConfirmationDialog(message: string, type: string) {
    if(!message) {
      return;
    }
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 5000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: type,
      },
    });
  }

}
