import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource, PageEvent, Sort } from '@angular/material';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { ListSort } from 'src/app/models/list-sort';
import { KidRegSummary } from 'src/app/models/school/kid-reg-summary';
import { StudentFilters } from 'src/app/models/school/student-filters';
import { SchoolManageService } from 'src/app/services/school-manage.service';
import * as _moment from "moment";
import { DEFAULT_LIMIT, DEFAULT_PAGE } from 'src/app/models/table-defaults';
import { SelectListItem } from 'src/app/models/select-list-item';

import { Router } from '@angular/router';
import { StudentsForm } from 'src/app/models/school/students-form';
import { Fee } from 'src/app/models/school/fee';
import { StudentsDownloadRequest } from 'src/app/models/school/students-download-request';
import { StudentsDownload } from 'src/app/models/school/students-download';
import { ConfirmationSnackbarComponent } from '../confirmation-snackbar/confirmation-snackbar.component';
const moment = _moment;
@Component({
  selector: 'app-ieb-students',
  templateUrl: './ieb-students.component.html',
  styleUrls: ['./ieb-students.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IebStudentsComponent implements OnInit, OnDestroy {
  VM$ = this.schoolService.studentsForm$.pipe(
    map((response) => {
    this.setSelectLists(response)
    return response;
  }));
  private dataSource = new MatTableDataSource<KidRegSummary>();
  dataSource$ : Observable<MatTableDataSource<KidRegSummary>> = this.schoolService.students$.pipe(
    map((response) => {
      const dataSource = this.dataSource;
      dataSource.data = response.items!;
      this.pageNumber = response.request.pageNumber!;
      this.itemsPerPage = response.request.itemsPerPage!;
      this.totalCount = response.totalCount!
      return dataSource;
    })
  );

  @ViewChild(MatSort, {static : false}) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild('paginator', { static: false }) private paginator: MatPaginator;

  displayedColumns: string[] = ['level', 'name', 'email', 'parentName', 'parentEmail', 'parentPhone'];
  pageNumber!: number;
  itemsPerPage!:number;
  totalCount!: number;
  pageSizes = [10, 20];
  listSort!: ListSort;
  filter: StudentFilters;
  selectedSemester: SelectListItem;
  selectedLevel: SelectListItem;
  semester: string;
  level: number;
  subscription: Subscription = new Subscription();
  selectedDownload: SelectListItem;
  fees: Fee[];
  private searchSub = new Subject<string>();

  constructor(private schoolService: SchoolManageService, private router: Router,  private snackBar: MatSnackBar) { }

  ngOnInit() {
    let month = moment().clone().local().month() + 1;
    let year = moment().clone().local().year();
    this.semester = month > 5 ? `Fall ${year}` : `Spring ${year}`;
    this.filter = {
      query: '',
      semester: this.semester
    };
     let searchSubscription$ = this.searchSub.pipe(debounceTime(500)).subscribe((query: string) => {
      this.onSearchChanged(query);
    });
    this.subscription.add(searchSubscription$);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setSelectLists(response: StudentsForm){
    this.selectedSemester = response.semesterList.find(x=>x.label === this.semester);
    this.selectedLevel = response.levelList[0];
    this.selectedDownload = response.downloadList[0];
    //this.downloadList = response.downloadList;
    this.fees = response.semesters;
  }

  onPageChanged(event: PageEvent) : void {
    this.itemsPerPage = event.pageSize;
    this.pageNumber = event.pageIndex + 1;
    let request = {
      filter: this.filter,
      pageNumber: this.pageNumber,
      itemsPerPage: this.itemsPerPage
     };
     this.schoolService.studentPaging(request);
  }

  onSort(sort: Sort): void {
    let listSort = {
      sortKey: sort.active,
      direction: sort.direction
    };
    this.listSort = listSort;
    let request = {
      filter: this.filter,
      pageNumber: this.pageNumber,
      pageSize: this.itemsPerPage,
      sort: this.listSort
     };
    this.schoolService.studentSorting(request);
  }

  onSemesterChange(selected: string): void {
    this.semester = selected;
    this.filter.semester = this.semester;
    let request = {
      filter: this.filter,
      pageNumber: DEFAULT_PAGE,
      itemsPerPage: DEFAULT_LIMIT
     };
    this.schoolService.semesterChange(request);
    this.paginator.pageIndex = 0;
  }

  onLevelChange(selected: number): void {
    this.level = selected;
    this.filter.levelId = this.level;
    let request = {
      filter: this.filter,
      pageNumber: DEFAULT_PAGE,
      itemsPerPage: DEFAULT_LIMIT
     };
    this.schoolService.levelChange(request);
  }

  onSearchChanged(query: string): void {
    let request = {
      filter: {
        query: query,
        semester: this.semester
      },
      pageNumber: DEFAULT_PAGE,
      itemsPerPage: DEFAULT_LIMIT
     };
    this.schoolService.studentSearch(request);
  }
  onDownload(selected: number): void {
    let selectedFee = this.fees.find(x=> x.id == selected);
    let request : StudentsDownloadRequest = {
      startYear: moment(selectedFee.validFrom).clone().local().year(),
      endYear : moment(selectedFee.validTill).clone().local().year(),
      startSemester : selectedFee.type === 'Year' ? selectedFee.description.split('-')[0] : selectedFee.description,
      endSemester: selectedFee.type === 'Year' ? selectedFee.description.split('-')[1] : selectedFee.description,
    };
    let downloadSubscription  = this.schoolService.downloadStudents(request).subscribe((data) => {
      if(data && data.length > 0) {
        const csv = this.generateCsv(data);
        this.downloadCsv(csv, selectedFee.description);
      } else {
        this.showConfirmationDialog(`No registration found for ${selectedFee.description}`, 'warning')
      }

    });
    this.subscription.add(downloadSubscription);
  }

  studentSelection(schoolKidId: number) : void {
    this.router.navigate(['ieb/Manage/student', schoolKidId]);
  }

  generateCsv(downloadList: StudentsDownload[]) {
    const csvRows = [];
    const headers = Object.keys(downloadList[0]);
    const newHeaders = [
      'Name',
      'Email',
      'Gender',
      'Level',
      'ArabicSkill',
      'QuranSkill',
      'Semester',
      'RegistrationType',
      'RegistrationDate',
      'ParentName',
      'ParentEmail',
      'ParentPhone'
    ];
    csvRows.push(newHeaders.join(','));
    for (const row of downloadList) {
      const values = headers.map((header) => {
        const escaped = ('' + row[header]).replace('"', '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }

  downloadCsv(csvData: any, semester: string) {
    const fileName = `students-registration-${semester}-${moment().format('MM-DD-YYYY')}`;
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  showConfirmationDialog(message: string, type: string) {
    if(!message) {
      return;
    }
    this.snackBar.openFromComponent(ConfirmationSnackbarComponent, {
      horizontalPosition: "center",
      duration: 5000,
      panelClass: "message",
      data: {
        dismissSnackbar: () => {
          this.snackBar.dismiss();
        },
        displayText: message,
        type: type,
      },
    });
  }

}
