import { ConfigService } from "./../../services/config.service";
import { TenantNoLeaseDisplayValues } from "../../models/tenant-no-lease-display-values";
import { TenantWaitInfo } from "./../../models/tenant-wait-info";
import { TenantWaitService } from "./../../services/tenant-wait.service";
import { Component, Input, OnInit } from "@angular/core";
import * as _moment from "moment";
import { TenantNoLease } from "src/app/models/tenant-no-lease";
import { TenantService } from "src/app/services/tenant.service";
import { TenantInfo } from "src/app/models/tenant-info";
const moment = _moment;
@Component({
  selector: "app-wait-list",
  templateUrl: "./wait-list.component.html",
  styleUrls: ["./wait-list.component.scss"],
})
export class WaitListComponent implements OnInit {
  @Input() hideAdd: boolean;
  displayedColumns: string[] = [
    "name",
    "verified",
    "room",
    "start",
    "requestDate",
    "parking",
  ];
  list: TenantNoLease[];
  dataSource: TenantNoLeaseDisplayValues[];
  totalRows = 0;
  totalRowsText = "";
  loaded: Promise<boolean>;
  notLoading = true;
  showEmpty: boolean;
  showSummary: boolean;
  summaryName: string;
  summaryTenantWaitName: string;
  waitDetails: TenantWaitInfo;
  tenantDetails: TenantInfo;
  summaryId: number;
  summaryMemberId: number;
  summaryType: string;
  summaryEmail: string;
  hideSummary: boolean;

  constructor(
    private waitService: TenantWaitService,
    private configService: ConfigService,
    private tenantService: TenantService
  ) {}

  ngOnInit() {
    this.getList();
  }
  getList() {
    this.loaded = Promise.resolve(false);
    this.waitService.tenantWaitList().subscribe((data) => {
      if (data) {
        this.list = Object.assign([], data);
      }
      if (this.list.length === 0) {
        this.showEmpty = true;
      }
      this.totalRows = this.list.length;
      if (this.totalRows) {
        const totalRequestRows = this.list.filter((l) => l.type === "T").length;
        const totalWaitRows = this.list.filter((l) => l.type === "W").length;
        this.totalRowsText =
          totalRequestRows && totalWaitRows
            ? `${totalRequestRows} Tenant, ${totalWaitRows} Wait Request(s)`
            : totalRequestRows && !totalWaitRows
            ? `${totalRequestRows} Tenant Request(s)`
            : !totalRequestRows && totalWaitRows
            ? `${totalWaitRows} Wait Request(s)`
            : "";
      }

      //this.pageNumber = this.listResponse.request.pageNumber;
      this.setDataSource(this.list);
      this.loaded = Promise.resolve(true);
    });
  }

  setDataSource(tenants: TenantNoLease[]) {
    let dataSource: TenantNoLeaseDisplayValues[] = [];
    dataSource = this.list.map((value) => ({
      id: value.type === "T" ? value.tenant.id : value.tenantWait.id,
      name:
        value.type === "T"
          ? `${value.tenant.memberInfo.firstName} ${value.tenant.memberInfo.lastName}`
          : `${value.tenantWait.memberInfo.firstName} ${value.tenantWait.memberInfo.lastName}`,
      email:
        value.type === "T"
          ? value.tenant.memberInfo.email
          : value.tenantWait.memberInfo.email,
      referenceChecked:
        value.type === "T" && value.tenant.isVerified ? "Yes" : "No",
      room:
        value.type === "T"
          ? `${value.tenant.roomInfo.name}/${value.tenant.roomInfo.occupancy}`
          : value.tenantWait.roomType,
      startDate:
        value.type === "T"
          ? value.tenant.tentativeStartDate
          : value.tenantWait.tentativeStartDate,
      requestDate: value.type === "T" ? "N/A" : value.tenantWait.requestDate,
      parkingSpace:
        value.type === "T" && value.tenant.parkingSpaceRent ? "Yes" : "N/A",
      type: value.type,
    }));
    this.dataSource = dataSource;
  }

  hideWaitSummary() {
    this.showSummary = false;
    this.hideSummary = true;
  }

  showWaitSummary(id: number, type: string) {
    this.waitDetails = null;
    this.tenantDetails = null;
    this.summaryId = id;
    this.summaryType = type;
    let selectedWait =
      type === "T"
        ? this.list.find((x) => x.tenant && x.tenant.id === id)
        : this.list.find((x) => x.tenantWait && x.tenantWait.id === id);
    this.summaryMemberId =
      type === "T"
        ? selectedWait.tenant.memberInfo.id
        : selectedWait.tenantWait.memberInfo.id;
    if (this.summaryType === "T") {
      this.getTenantDetails();
    } else {
      this.getWaitDetails();
    }

    this.summaryName =
      type === "T"
        ? `${selectedWait.tenant.memberInfo.firstName} ${selectedWait.tenant.memberInfo.lastName}`
        : `${selectedWait.tenantWait.memberInfo.firstName} ${selectedWait.tenantWait.memberInfo.lastName}`;
    this.summaryEmail =
      type === "T"
        ? selectedWait.tenant.memberInfo.email
        : selectedWait.tenantWait.memberInfo.email;
    //this.getSummaryUser();
    this.showSummary = true;
    this.hideSummary = false;
  }

  getWaitDetails() {
    if (!this.summaryId) {
      return;
    }
    this.waitService.tenantWaitGet(this.summaryId).subscribe((data) => {
      if (data) {
        this.waitDetails = Object.assign({}, data);
        this.waitDetails.requestDate = !this.waitDetails.requestDate
          ? "-"
          : moment(this.waitDetails.requestDate).clone().format("MM-DD-YYYY");
        if (this.waitDetails.files && this.waitDetails.files.length > 0) {
          this.waitDetails.files.map((file) => {
            if (file) {
              file.url = `${this.configService.config.fileBaseUrl}${file.storedFileName}`;
            }
          });
        }
      }
    });
  }
  getTenantDetails() {
    if (!this.summaryMemberId) {
      return;
    }
    this.tenantService
      .tenantGetByMember(this.summaryMemberId)
      .subscribe((data) => {
        if (data) {
          this.tenantDetails = Object.assign(data);
          if (this.tenantDetails.files && this.tenantDetails.files.length > 0) {
            this.tenantDetails.files.map((file) => {
              if (file) {
                file.url = `${this.configService.config.fileBaseUrl}${file.storedFileName}`;
              }
            });
          }
        }
      });
  }

  updateList(waitId: number) {
    const indx = this.list.findIndex(
      (w) => w.tenantWait && w.tenantWait.id === waitId
    );
    if (indx !== -1) {
      this.getList();
      this.hideWaitSummary();
    }
  }

  updateTenant(tenantUpdated: TenantInfo) {
    let index = this.list.findIndex(
      (t) => t.tenant && t.tenant.id == tenantUpdated.id
    );
    this.list.splice(index, 1);
    let item = this.dataSource.find((d) => d.id == tenantUpdated.id);
    item.room = tenantUpdated.roomInfo
      ? `${tenantUpdated.roomInfo.name}/${tenantUpdated.roomInfo.occupancy}`
      : "-";
  }

  setVerification(verified: boolean) {
    if (verified) {
      var row = this.dataSource.find((x) => x.id === this.summaryId);
      if (row) {
        row.referenceChecked = "Yes";
      }
    }
  }

  deleteTenant(tenantId: number) {
    const indx = this.list.findIndex(
      (w) => w.tenant && w.tenant.id === tenantId
    );
    if (indx !== -1) {
      this.getList();
      this.hideWaitSummary();
    }
  }
}
